import React from "react";
import '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReact, faDocker, faPython } from '@fortawesome/free-brands-svg-icons';
import Chip from '@mui/material/Chip';
import '../assets/styles/Expertise.scss';

const labelsFirst = [
    "Microsoft Visio",
    "Lucidchart",
    "SQL",
    "PostgreSQL",
    "JIRA",
    "Confluence",
    "Microsoft Power BI",
    "Tableau",
    "Balsamiq",
    "Axure",
    "Microsoft Excel (Advanced)",
    "SAP",
    "Oracle EBS"
];

const labelsThird = [
    "Python (Pandas, NumPy, SciKit-Learn, TensorFlow)",
    "SQL",
    "PostgreSQL",
    "Snowflake",
    "Microsoft Power BI",
    "Tableau",
    "R",
    "Google BigQuery",
    "AWS (Redshift, S3)",
    "Apache Spark",
    "Databricks",
    "Jupyter Notebooks"
];


const labelsSecond = [
    "JIRA",
    "Trello",
    "Asana",
    "Microsoft Project",
    "Smartsheet",
    "Confluence",
    "Slack",
    "Microsoft Teams",
    "ClickUp",
    "Monday.com",
    "GanttPro",
    "Wrike",
    "Azure DevOps",
    "GitHub Projects"
];


function Expertise() {
    return (
    <div className="container" id="expertise">
        <div className="skills-container">
            <h1>Expertise</h1>
            <div className="skills-grid">
                <div className="skill">
                    <FontAwesomeIcon icon={faReact} size="3x"/>
                    <h3>Business Analysis</h3>
                    <p>I excel in bridging the gap between business needs and technical solutions, with a focus on identifying requirements, defining clear business goals, and driving strategic initiatives. I have extensive experience in stakeholder engagement, process optimization, and solution implementation.</p>
                    <div className="flex-chips">
                        <span className="chip-title">Tech stack:</span>
                        {labelsFirst.map((label, index) => (
                            <Chip key={index} className='chip' label={label} />
                        ))}
                    </div>
                </div>

                <div className="skill">
                    <FontAwesomeIcon icon={faDocker} size="3x"/>
                    <h3>Project Management</h3>
                    <p>I lead projects from initiation to completion, applying best practices in project management to ensure timely delivery and alignment with business objectives. I am proficient in managing cross-functional teams, risk mitigation, and executing Agile methodologies to achieve project success.</p>
                    <div className="flex-chips">
                        <span className="chip-title">Tech stack:</span>
                        {labelsSecond.map((label, index) => (
                            <Chip key={index} className='chip' label={label} />
                        ))}
                    </div>
                </div>

                <div className="skill">
                    <FontAwesomeIcon icon={faPython} size="3x"/>
                    <h3>Data Analytics</h3>
                    <p>I leverage data-driven insights to inform strategic decisions, utilizing advanced analytics techniques and tools. My expertise includes data modeling, predictive analytics, and data visualization to help businesses optimize their operations and drive growth.</p>
                    <div className="flex-chips">
                        <span className="chip-title">Tech stack:</span>
                        {labelsThird.map((label, index) => (
                            <Chip key={index} className='chip' label={label} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default Expertise;