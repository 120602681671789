import React, { useState, useEffect } from "react";
import {
  Main,
  Timeline,
  Expertise,
  Project,
  Contact,
  Navigation,
  Footer,
} from "./components";
import FadeIn from './components/FadeIn';
// eslint-disable-next-line 
import PortfolioCycleSVG from './components/PortfolioCycleSVG'; // Import the SVG-based component
// eslint-disable-next-line 
import { Skills } from './components/Skills';  // Import the Skills component
import './index.scss';

function App() {
    const [mode, setMode] = useState<string>('dark');

    const handleModeChange = () => {
        setMode(mode === 'dark' ? 'light' : 'dark');
    };

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <div className={`main-container ${mode === 'dark' ? 'dark-mode' : 'light-mode'}`}>
            <Navigation parentToChild={{ mode }} modeChange={handleModeChange} />
            
            

            
            
            <FadeIn transitionDuration={700}>
                <Main />
                <Expertise />
                <Timeline />
                <Project />
                <Contact />
            </FadeIn>
            
            <Footer />
        </div>
    );
}

export default App;
