import React from "react";
import '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import '../assets/styles/Timeline.scss'

function Timeline() {
  return (
    <div id="history">
      <div className="items-container">
        <h1>Career History</h1>
        <VerticalTimeline>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: 'white', color: 'rgb(39, 40, 34)' }}
            contentArrowStyle={{ borderRight: '7px solid  white' }}
            date="Jan 2024 - Aug 2024"
            iconStyle={{ background: '#5000ca', color: 'rgb(39, 40, 34)' }}
            icon={<FontAwesomeIcon icon={faBriefcase} />}
          >
            <h3 className="vertical-timeline-element-title">CoCo Fresh Tea & Juice</h3>
            <h4 className="vertical-timeline-element-subtitle">Business Analyst</h4>
            <p>
            • Led a project team in developing business cases and analyzing data to identify inefficiencies, resulting in a 50% reduction in peak-hour wage expenses.
            <br></br>
            <br></br>
            • Developed detailed reports and presentations to enhance stakeholder engagement and support evidence-based decision-making, aligning with business objectives.
            <br></br>
            <br></br>
            • Collaborated with the IT and project management team to implement an automated order taking system, optimizing process inefficiencies to achieve a 40% reduction in customer wait times.
            <br></br>
            <br></br>
            • Performed process mapping and redesign, leveraging qualitative and quantitative analysis techniques to identify gaps and optimize business processes.
            <br></br>
            <br></br>
            • Trained staff on new operations, ensuring smooth implementation and high team adaptation.
            

            </p>
          </VerticalTimelineElement>

        
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="Jan 2022 - Jan 2024"
            iconStyle={{ background: '#5000ca', color: 'rgb(39, 40, 34)' }}
            icon={<FontAwesomeIcon icon={faBriefcase} />}
          >
            <h3 className="vertical-timeline-element-title">Agincourt International Academy</h3>
            <h4 className="vertical-timeline-element-subtitle">Business Data Analyst</h4>
            • Established the main CRM database with Python and MySQL and managed a separate internal database using Excel (VBA & Macros), reducing resource costs by 30%.
            <br></br>
            <br></br>
            • Facilitated an E-learning initiative for over 1000 students, using certified STEM courseware tools and SQL integration to ensure a seamless transition to online learning.
            <br></br>
            <br></br>
            • Developed a predictive churn model with Python using logistic regression and random forest algorithms, achieving an 85% precision rate and reducing student churn by 25%.
            <br></br>
            <br></br>
            • Conducted data audits to ensure accuracy and compliance, enhancing data integrity.

          </VerticalTimelineElement>

          
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="Jun 2021 - Dec 2021"
            iconStyle={{ background: '#5000ca', color: 'rgb(39, 40, 34)' }}
            icon={<FontAwesomeIcon icon={faBriefcase} />}
          >
            <h3 className="vertical-timeline-element-title">AMB Driving School</h3>
            <h4 className="vertical-timeline-element-subtitle">Data Strategy Analyst</h4>
            • Designed and presented interactive data visualization dashboards using Tableau and Microsoft Power BI, enabling clear communication of critical performance indicators.
            <br></br>
            <br></br>
            • Implemented machine learning algorithms to analyze driving test pass rates and student performance, creating personalized learning plans and improving student success rates by approximately 40%.
            <br></br>
            <br></br>
            • Performed risk assessments and process simulations to improve business processes and decisions.
            <br></br>
            <br></br>
            • Led initiatives to re-engineer business processes, resulting in improved operational efficiency and enhanced process documentation.
            

          </VerticalTimelineElement>
         
          
        </VerticalTimeline>
      </div>
    </div>
  );
}

export default Timeline;