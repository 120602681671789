import React from "react";
import mock01 from '../assets/images/mock01.png';
import mock02 from '../assets/images/mock02.png';
import mock03 from '../assets/images/mock03.png';
import mock04 from '../assets/images/mock04.png';
import mock05 from '../assets/images/mock05.png';
import mock06 from '../assets/images/mock06.png';
import mock07 from '../assets/images/mock07.png';
import mock08 from '../assets/images/mock08.png';
import mock09 from '../assets/images/mock09.png';
import mock10 from '../assets/images/mock10.png';
import '../assets/styles/Project.scss';

function Project() {
    return(
    <div className="projects-container" id="projects">
        <h1>Personal Projects</h1>
        <div className="projects-grid">
            <div className="project">
                <a href="https://github.com/affannaushad/GenerativeAI-SMS-Marketing" target="_blank" rel="noreferrer"><img src={mock10} className="zoom" alt="thumbnail" width="100%"/></a>
                <a href="https://github.com/affannaushad/GenerativeAI-SMS-Marketing" target="_blank" rel="noreferrer"><h2>GenerativeAI Custom SMS Marketing Program</h2></a>
                <p>Developed an automated SMS marketing system using Generative AI and Python, reverse-engineering the TextNow web interface to improve customer retention for Safe 2 Drive Driving School. Created personalized messages with OpenAI's API to engage students, offering discounts and incentives for G test bookings. Leveraged Pandas for data processing, resulting in a significant increase in student return rates.
                </p>
            </div>
            <div className="project">
                <a href="https://github.com/affannaushad/spacex-ds-analysis" target="_blank" rel="noreferrer"><img src={mock09} className="zoom" alt="thumbnail" width="100%"/></a>
                <a href="https://github.com/affannaushad/spacex-ds-analysis" target="_blank" rel="noreferrer"><h2>SpaceX Data Science</h2></a>
                <p>Conducted a data science project to predict the success or failure of SpaceX Falcon 9 rocket landings. Gathered data through web scraping and API calls, performed data cleaning, and visualized findings using Matplotlib and Seaborn. Developed machine learning models achieving 83% accuracy and created interactive dashboards with Plotly and Dash. Presented insights and recommendations in a detailed PowerPoint presentation, showcasing advanced data science techniques and predictive analytics.</p>
            </div>
            <div className="project">
                <a href="https://github.com/affannaushad/Simple_MachineLearning_Models" target="_blank" rel="noreferrer"><img src={mock08} className="zoom" alt="thumbnail" width="100%"/></a>
                <a href="https://github.com/affannaushad/Simple_MachineLearning_Models" target="_blank" rel="noreferrer"><h2>Machine Learnign for Taxi Tip, Credit Card Fraud, and Rain Prediction</h2></a>
                <p>This repository features a collection of machine learning models designed to tackle real-world problems. It includes projects such as predicting taxi tips, forecasting rainfall in Australia, and detecting credit card fraud. These models highlight the application of machine learning techniques to solve practical challenges, demonstrating expertise in predictive analytics and the development of impactful solutions across different domains.</p>
            </div>
            <div className="project">
                <a href="https://github.com/affannaushad/Data-Science-Practice" target="_blank" rel="noreferrer"><img src={mock07} className="zoom" alt="thumbnail" width="100%"/></a>
                <a href="https://github.com/affannaushad/Data-Science-Practice" target="_blank" rel="noreferrer"><h2>Data Science Practice Repository</h2></a>
                <p>This repository serves as a strong resource for strengthing data science skills through a range of practical exercises and projects. It includes a variety of files and activities covering essential areas such as SQL for database management, Python for data analysis and manipulation, web scraping for data extraction, and data visualization techniques. Additionally, it features machine learning projects and exercises to build and evaluate models. This repository is ideal for anyone seeking to enhance their data science expertise through hands-on practice and real-world applications.
                </p>
            </div>
            <div className="project">
                <a href="https://github.com/affannaushad/SteelCompanyAutomation" target="_blank" rel="noreferrer"><img src={mock06} className="zoom" alt="thumbnail" width="100%"/></a>
                <a href="https://github.com/affannaushad/SteelCompanyAutomation" target="_blank" rel="noreferrer"><h2>Major Steel Company Data Cleaning </h2></a>
                <p>Assisted a major steel company in the Greater Toronto Area by automating a complex data formatting task. The project involved processing a large dataset of over 10,000 rows, which required extensive manual cleaning and formatting. Created a data blueprint to identify and resolve inconsistencies, then developed a script to automate the removal of unwanted initials and the extraction of job titles and company names into their respective columns. This automation reduced what would have been several days of manual work to just a few minutes, significantly saving time and improving efficiency.</p>
            </div>
            <div className="project">
                <a href="" target="_blank" rel="noreferrer"><img src={mock05} className="zoom" alt="thumbnail" width="100%"/></a>
                <a href="" target="_blank" rel="noreferrer"><h2>UFC Fight Prediction Model</h2></a>
                <p>In this project, we analyzed UFC fight data by web scraping, cleaning, and formatting extensive datasets. We conducted thorough data wrangling and visualization to extract valuable insights, which were then presented using interactive dashboards created with Power BI. Additionally, we developed a machine learning model to predict UFC fight outcomes based on fighter statistics and records, providing advanced forecasting and analysis of fight results.</p>
            </div>
        </div>
    </div>
    );
}

export default Project;